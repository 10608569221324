import { db } from "../index";

import {
  collection,
  getDocs,
  setDoc,
  doc,
  deleteDoc,
  getDoc,
  where,
  query,
  orderBy,
} from "firebase/firestore";
import { v4 as uuid } from "uuid";

export async function getemails(city) {
  try {
    const emailsRef = collection(db, "emails");

    let arrayemails = [];

    const q = query(emailsRef, where("city", "==", city));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const monta = {
        id: doc.id,
        ...doc.data(),
      };

      arrayemails.push(monta);
    });

    return arrayemails;
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

export async function updateemails({ email, password, city }, id, type) {
  try {
    if (type == "edit") {
      await setDoc(doc(db, "emails", id), {
        email,
        password,
        city,
      });
    } else {
      const idNew = uuid();
      await setDoc(doc(db, "emails", idNew), {
        email,
        password,
        city,
      });
    }
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

export async function getPlan(id) {
  try {
    const docRef = doc(db, "emails", id);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();
    return data;
  } catch (error) {
    console.log(error);
  }
}
